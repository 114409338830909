@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  @font-face {
    font-family: Inter;
    src: url('/fonts/Inter.ttf') format("truetype-variations");
    font-weight: 1 999;
  }

  @font-face {
    font-family: Cabin;
    src: url('/fonts/Cabin.ttf') format("truetype-variations");
    font-weight: 1 999;
  }

  html,
  body {
    font-family:  Inter, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    overflow-x: hidden;
  }

  .font-cabin {
    font-family:  Cabin, Inter, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }


  ::selection {
    background: #FED7D7;
    /* WebKit/Blink Browsers */
  }

  ::-moz-selection {
    background: #FED7D7;
    /* Gecko Browsers */
  }

  .filepond--root {
    font-family: Inter, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
  }
}

@layer components {
  .section-heading {
   @apply text-3xl tracking-tight font-extrabold md:text-5xl ;
  }

  .section-para {
    @apply text-lg text-gray-500;
  }
  
}